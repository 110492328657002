import React from 'react'
import styled from 'styled-components'
import { Section } from '../components/shared'
import { PRIMARY } from '../constants/colors'
import { maxWidth, PHONE } from '../constants/measurements'
import { CLIENT_NAME, CONTACT_EMAIL } from '../constants/routes'
import { withErrorHandler } from '../components/errorHandler'

import Layout from '../templates/Layout'

const Wrap = styled.div`
  display: flex;
  justify-content: flex;
  align-items: center;
`

const Container = styled.div`
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  max-width: 500px;
`

const Title = styled.div`
  color: ${PRIMARY};
  font-size: 48px;
  line-height: 55px;
  letter-spacing: 1px;
  margin-bottom: 24px;
  text-transform: capitalize;

  ${maxWidth(PHONE)} {
    font-size: 32px;
  }
`

const Body = styled.div`
  color: ${PRIMARY};
  font-size: 18px;
`

const Contact = () => (
  <Layout>
    <Section>
      <Wrap>
        <Container>
          <Title>Contact {CLIENT_NAME}</Title>
          <Body>
            If you have any questions or concerns, contact us at
            <a href={`mailto:${CONTACT_EMAIL}?subject=Membership Support`}>
              {' '}
              {CONTACT_EMAIL}
            </a>
            .
          </Body>
        </Container>
      </Wrap>
    </Section>
  </Layout>
)

export default withErrorHandler(Contact)
